import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emaillogin',
  templateUrl: './emaillogin.component.html',
  styleUrls: ['./emaillogin.component.scss']
})
export class EmailloginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
